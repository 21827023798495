<template >
  <div id="caller">
    <div id="date">{{ date }}</div>
    <div id="heure">{{ time }}</div>
    
    <div ></div>

    <transition-group id="ticketing" name="flip-list" tag="div">
      <div class="ticket" v-for="ticket in tickets" v-bind:key="ticket.numero">
        {{ticket.numero}} => {{ticket.service}}
      </div>
    </transition-group>
    <audio
      ref="audio"
      src="@/assets/audio/2.mp3"
      preload
      id="audio"
      muted
    ></audio>
  </div>
</template>


<script>
export default {
  name: 'Calling',
  props: {
    msg: String
  },
  data: function () {
    return {
      index :5,
      time: '',
      date: '',
      week : ['DIMANCHE', 'LUNDI', 'MARDI', 'MERCREDI', 'JEUDI', 'VENDREDI', 'SAMEDI'],
      timerID: "",
      tickets: [
      ],

      nextNum: 0,
      selectedVoice: 0,
      synth: window.speechSynthesis,
      greetingSpeech: new window.SpeechSynthesisUtterance(),
    }
  },
  mounted: function(){
    this.timerID = setInterval(this.updateTime, 1000);
    this.updateTime()

    // Ecoute du chanel pour la mise à jour du tableau (Le numéro)
    const eventSource = new EventSource("https://mercure.vit.ci/.well-known/mercure?topic=https://example.com/notif/");
    // const eventSource = new EventSource("http://192.168.8.103:3000/.well-known/mercure?topic=https://example.com/notif/");
    eventSource.onmessage = ({ data }) => {
      var ticket =JSON.parse(data)
      if(ticket.type=="call"){
        this.call(ticket.data.servstr+""+ticket.data.numero,ticket.data.guichet.code)
      }else if(ticket.type=="recall"){
        this.recall(ticket.data.servstr+""+ticket.data.numero,ticket.data.guichet.code)
      }
    };

        // this.speak(`Le numéro 09 est appelé `)


  },
  methods: {
    zeroPadding: function(num, digit) {
    var zero = '';
    for(var i = 0; i < digit; i++) {
      zero += '0';
    }
      return (zero + num).slice(-digit);
    },
    
    updateTime: function() {
      var cd = new Date();
      this.time = this.zeroPadding(cd.getHours(), 2) + ':' + this.zeroPadding(cd.getMinutes(), 2) + ':' + this.zeroPadding(cd.getSeconds(), 2);
      this.date =  this.week[cd.getDay()] +" " +this.zeroPadding(cd.getDate(), 2) + ' ' + this.zeroPadding(cd.getMonth()+1, 2) + ' ' +this.zeroPadding(cd.getFullYear(), 4) ;
    },

    randomIndex: function () {
      return Math.floor(Math.random() * this.tickets.length)
    },
    
    call: function (num,guichet) {
      if(this.tickets.length<5){
        var index = this.randomIndex();
        this.tickets.splice(index, 0, {
          numero:num,
          service:guichet
        })
        num = num.split("").join(" ");
        console.log(num);
        // this.speak(`Le numéro ${num} est appelé`)
      this.playSound();

      }else{
        this.remove(num,guichet);
      }
    },
    recall: function (num,guichet) {
      if(this.tickets.length<5){
        this.tickets.splice(this.tickets.indexOf({numero:num,service:guichet}), 1, {
          numero:num,
          service:guichet
        })
        num = num.split("").join(" ");
        console.log(num);

        // this.speak(`Le numéro ${num} est rappelé`)
      this.playSound();

      }else{
        this.remove(num,guichet);
      }
    },
    remove: function (num,guichet) {
      // this.tickets.shift();
      this.tickets.splice (this.tickets.indexOf(this.tickets[0]), 0);
      var index = this.randomIndex();
      this.tickets.splice(index, 1,{
        numero:num,
        service:guichet
      })
    },
    speak: function(text){
      // this.playSound(require('@/assets/audio/1.mp3'));
      this.greetingSpeech.voice=this.synth.getVoices()[3];

      this.greetingSpeech.text = text;
      this.synth.speak(this.greetingSpeech);
    },

    playSound () {
        let audio = this.$refs.audio;
        audio.pause();
        audio.currentTime = 0;


        audio.play();
      
    },

    toggleSound() {
        let audio = this.$refs.audio;
        if (
          audio.paused &&
          document.querySelector(".toggle-sound").classList.contains("paused")
        ) {
          console.log("play it")
          audio.play();
          document.querySelector(".toggle-sound").classList.remove("paused");
        } else {
          console.log("pause it")
          audio.pause();
          document.querySelector(".toggle-sound").classList.add("paused");
        }
      },
  }
}
</script>

<style scoped>
  #caller{
    display: flex;
    flex-direction: column;
    height:100%;
    background-color: #196faa;
  }

  #date{
    height: 5%;
    font-size: 30px;
    color:#FFF;
    font-weight: bold;
    text-transform: uppercase;
  }

  #heure{
    height: 10%;
    background-color: #091826;
    font-size: 60px;
    color:#FFF;
    margin-bottom: 10px;
  }

  #ticketing{
    height: 85%;
  }

  .ticket{
    background-color: #f47842; 
    height:15%; 
    font-size:70px;
    font-weight: bold;
    text-transform: uppercase;
    color:#FFF;
    margin-bottom: 10px;
  }

  .flip-list-item {
    transition: all 1s;
    display: inline-block;
    margin-right: 10px;
  }
  .flip-list-enter, .flip-list-leave-to {
    opacity: 0;
    transform: translateY(30px);
  }
  .flip-list-leave-active {
    position: absolute;
  }
  
</style>