<template>
  <div class="home">
    <Pub />

    <Calling />
  </div>
</template>

<script>
// @ is an alias to /src
import Calling from '@/components/tableau/Calling.vue'
import Pub from '@/components/tableau/Pub.vue'
import axios from 'axios';


export default {
  name: 'Home',
  
  components: {
    Pub,
    Calling
  },
  data: function(){
  },
  mounted: function(){
    const HTTP = axios.create({
      // baseURL: "http://127.0.0.1:5000/",
      baseURL: "https://app.vit.ci/",
      headers: {
        "Content-Type": "application/json"
      }
    })
    HTTP.post("check_agences", {code:this.$route.params.code})
    .then(response => this.articleId = response.data.id);

  }
}
</script>

<style scoped>
.home{
  display:flex;
  height:100%;
}
#pub{
  width: 65%
}

#caller{
  width: 35%
}
</style>