<template >
    <div id="pub">
      <!-- <div id="media" class="col-md-9" style="position: relative;height: 100%;text-align: center;" v-html='pub'></div> -->
      <div id="media" class="col-md-9" style="position: relative;height: 100%;text-align: center;">
    <transition-group name="fade" tag="div" style="width: 100%;height: 100%;">
      <div style="width: 100%;height: 100%;" v-for="i in [currentIndex]" :key="i">
        <img style="width: 100%;height: 100%;" alt="Podipo pub" :src="currentImg" />
      </div>
    </transition-group>
  </div>
    </div>
</template>


<script>
import axios from 'axios';
export default {
  name: 'Pub',
  props: {
    msg: String
  },

  data: function() {
    return {
      publicPath: process.env.BASE_URL,
      media: [
        {content:'<img style="width: 100%;height: 100%;" alt="Podipo pub" src="'+this.getImgUrl("3212636.jpg")+'">'}
      ],
      images: [
        "https://app.vit.ci/app/static/uploads/image001-2.png",
      ],
      timer: null,
      currentIndex: 0
    }
  },

  mounted:function(){
    const HTTP = axios.create({
      baseURL: "https://app.vit.ci/",
      // baseURL: "http://192.168.8.103:5000/",
      headers: {
        "Content-Type": "application/json"
      }
    })
     HTTP.post("get_publicite", {code:this.$route.params.code})
    .then(response => this.images = response.data.publicite);

    // this.pub = this.media[0].content;

    

     this.startSlide();
  },

  methods:{
    getImgUrl(pic){
      return require('../../assets/'+pic);
    },
    startSlide: function() {
      this.timer = setInterval(this.next, 4000);
    },

    next: function() {
      this.currentIndex += 1;
    },
    prev: function() {
      this.currentIndex -= 1;
    }
  },
  computed: {
    currentImg: function() {
      return this.images[Math.abs(this.currentIndex) % this.images.length];
    }
  }
}
</script>

<style scoped>
   .fade-enter-active,
.fade-leave-active {
  transition: all 0.9s ease;
  overflow: hidden;
  visibility: visible;
  position: absolute;
  width:100%;
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  visibility: hidden;
  width:100%;
  opacity: 0;
}

img {
  height:600px;
  width:100%
}

.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 40%;
  width: auto;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.7s ease;
  border-radius: 0 4px 4px 0;
  text-decoration: none;
  user-select: none;
}

.next {
  right: 0;
}

.prev {
  left: 0;
}

.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.9);
} 
</style>